import jsonApi from '../../../../json-api-client';
import dateFormatter from '../../../../i18n/dateFormatter';
import moment from 'moment';
import * as CalendarMode from '../../../../enums/CalendarMode';
import * as CalendarFilterMode from '../../../../enums/CalendarFilterMode';
import { stateFromJsonApiResponse } from '../../../helpers';
import apiUrl from '../../../../services/apiUrl';
import i18n from '../../../../i18n';
import DialogManager from '../../../../services/DialogManager';
import CalendarItemWithPerformanceCodeParticipation
    from '../../../../definitions/CalendarItemWithPerformanceCodeParticipation';
import CalendarItemWithSubscriptionParticipation
    from '../../../../definitions/CalendarItemWithSubscriptionParticipation';
import debounce from '../../../../services/debounce';
import { handleIgnorableErrorsResponse } from '../../../../services/ignorableErrors';
import axios from 'axios';

const debounceTime = Number(document.head.querySelector('meta[name="calendar-debounce-time"]')?.content || 250);

let previousSourceEvents;

const internalFetchEvents = (context) => {
    if (previousSourceEvents) {
        previousSourceEvents.cancel();
        previousSourceEvents = null;
    }

    if (!context.state.filter.mode || context.state.filter.value.length === 0) {
        context.commit('setEvents', {});
        context.commit('setLoading', { type: 'events', loading: false });

        return;
    }

    previousSourceEvents = jsonApi.axios.CancelToken.source();

    const daysOfWeek = context.state.mode === CalendarMode.DAY_PLUS_SEVEN ? context.getters.date.clone().locale('en').format('ddd').toLowerCase() : null;

    return jsonApi.findAll('calendar_items', {
        _cancelToken: previousSourceEvents.token,
        start_date: context.getters.startDate.format('YYYY-MM-DD'),
        end_date: context.getters.endDate.format('YYYY-MM-DD'),
        anonymous_descriptions: context.state.anonymous,
        ...(daysOfWeek ? { days_of_week: daysOfWeek } : {}),
        [context.state.filter.mode]: context.state.filter.value.join(','),
    }).then(response => {
        const events = response.data.reduce((acc, item) => {
            const hash = context.getters.hashForEvent(item);

            return { ...acc, [hash]: { ...item, hash } };
        }, {});

        context.commit('setEvents', events);
    }).catch(error => {
        if (jsonApi.axios.isCancel(error)) {
            return;
        }

        // eslint-disable-next-line no-console
        console.error(error);

        return DialogManager.alert(i18n.t('base.errors.server'));
    }).finally(() => context.commit('setLoading', { type: 'events', loading: false }));
};

const debouncedFetchEvents = debounce((context) => internalFetchEvents(context), debounceTime);

export const fetchEvents = async(context, immediate = false) => {
    context.commit('setLoading', { type: 'events', loading: true });

    if (immediate) {
        return internalFetchEvents(context);
    }

    return debouncedFetchEvents(context);
};

let previousSourceSchedules;

const internalFetchSchedules = (context) => {
    if (previousSourceSchedules) {
        previousSourceSchedules.cancel();
        previousSourceSchedules = null;
    }

    let model = null;
    const params = {
        start_date: context.getters.startDate.format('YYYY-MM-DD'),
        end_date: context.getters.endDate.format('YYYY-MM-DD'),
    };
    switch (context.state.filter.mode) {
        case CalendarFilterMode.EMPLOYEES:
            model = 'calendar_employee_schedule';
            params.employee_ids = context.state.filter.value.join(',');
            break;
        case CalendarFilterMode.ROOMS:
            model = 'calendar_location_room_schedule';
            params.location_room_ids = context.state.filter.value.join(',');
            break;
        case CalendarFilterMode.DEVICES:
            model = 'calendar_location_device_schedule';
            params.location_device_ids = context.state.filter.value.join(',');
            break;
    }

    if (model === null || context.state.filter.value.length === 0) {
        context.commit('setSchedules', {});
        context.commit('setLoading', { type: 'schedules', loading: false });

        return;
    }

    const daysOfWeek = context.state.mode === CalendarMode.DAY_PLUS_SEVEN ? context.getters.date.clone().locale('en').format('ddd').toLowerCase() : null;
    if (daysOfWeek) {
        params.days_of_week = daysOfWeek;
    }

    previousSourceSchedules = jsonApi.axios.CancelToken.source();

    return jsonApi.findAll(model, {
        _cancelToken: previousSourceSchedules.token,
        ...params,
    }).then(response => {
        const schedules = response.data.reduce((acc, item) => {
            return { ...acc, [item.id]: item };
        }, {});

        context.commit('setSchedules', schedules);
    }).catch(error => {
        if (jsonApi.axios.isCancel(error)) {
            return;
        }

        // eslint-disable-next-line no-console
        console.error(error);

        return DialogManager.alert(i18n.t('base.errors.server'));
    }).finally(() => context.commit('setLoading', { type: 'schedules', loading: false }));
};

const debouncedFetchSchedules = debounce((context) => internalFetchSchedules(context), debounceTime);

export const fetchSchedules = async(context, immediate) => {
    context.commit('setLoading', { type: 'schedules', loading: true });

    if (immediate) {
        return internalFetchSchedules(context);
    }

    return debouncedFetchSchedules(context);
};

export const fetchLocations = (context) => {
    context.commit('setLoading', { type: 'locations', loading: true });

    return jsonApi.findAll('locations').then(response => {
        context.commit('setLocations', stateFromJsonApiResponse(response));
        context.commit('setActiveLocations', response.data.map(location => location.id));
        context.commit('setLoading', { type: 'locations', loading: false });
    });
};

export const fetchEmployees = (context) => {
    context.commit('setLoading', { type: 'employees', loading: true });

    return jsonApi.findAll('employees', { include: 'employee_groups' }).then(response => {
        context.commit('setEmployees', stateFromJsonApiResponse(response));
        context.commit('setLoading', { type: 'employees', loading: false });
    });
};

export const fetchRooms = (context) => {
    context.commit('setLoading', { type: 'rooms', loading: true });

    return jsonApi.findAll('location_rooms', { include: 'location' }).then(response => {
        context.commit('setRooms', stateFromJsonApiResponse(response));
        context.commit('setLoading', { type: 'rooms', loading: false });
    });
};

export const fetchDevices = (context) => {
    context.commit('setLoading', { type: 'devices', loading: true });

    return jsonApi.findAll('location_devices', { include: 'location' }).then(response => {
        context.commit('setDevices', stateFromJsonApiResponse(response));
        context.commit('setLoading', { type: 'devices', loading: false });
    });
};

export const fetchAll = (context) => {
    context.dispatch('fetchEvents', true);
    context.dispatch('fetchSchedules', true);
    context.dispatch('fetchLocations');
    context.dispatch('fetchEmployees');
    context.dispatch('fetchRooms');
    context.dispatch('fetchDevices');
};

export const deleteEvent = (context, id) => {
    return jsonApi.destroy('calendar_item', id)
        .then(() => {
            if (context.state.activeMutation && context.state.activeMutation.id === id) {
                context.commit('setActiveMutation', null);
            }

            return context.dispatch('fetchEvents');
        }).catch(error => DialogManager.errors(error));
};

export const deleteNote = (context, id) => {
    return jsonApi.destroy('calendar_note', id)
        .then(() => {
            if (context.state.activeMutation && context.state.activeMutation.id === id) {
                context.commit('setActiveMutation', null);
            }

            return context.dispatch('fetchEvents');
        }).catch(error => DialogManager.errors(error));
};

export const deleteRecurringEvent = async(context, id) => {
    let response;
    try {
        response = await jsonApi.axios.delete(apiUrl(`/calendar_items/${id}?include=recurrences`));
    } catch (err) {
        await DialogManager.errors(err);
        return;
    }

    if (response.data?.data?.type === 'calendar_item_removal_result' && response.data?.data?.attributes.errors.length) {
        const errors = response.data.data.attributes.errors;
        await DialogManager.alert(`<ul><li>${errors.join('</li><li>')}</li></ul>`);
    }

    if (context.state.activeMutation && context.state.activeMutation.id === id) {
        context.commit('setActiveMutation', null);
    }

    await context.dispatch('fetchEvents');
};

export const updateEvent = (context, data) => {
    const event = data.event;
    const force = data.force;

    return jsonApi.find('calendar_item', event.extendedProps.apiId)
        .then(response => response.data)
        .then(apiEvent => {
            const updatedEvent = { ...data.apiEvent || {} };

            updatedEvent.start = dateFormatter(event.start);
            if (event.end) {
                updatedEvent.end = dateFormatter(event.end);
            }

            return { ...updatedEvent, type: apiEvent.type, id: apiEvent.id };
        })
        .then(apiEvent => {
            // When POST- or PATCH-ing the item the API requires the type to be suffixed with '_resource'.
            return jsonApi.update(apiEvent.type + '_resource', apiEvent, { force });
        })
        .then(() =>
            axios.post(`/calendar/appointment/${event.extendedProps.apiId}/forget-payment-status`).then())
        .then(() => context.dispatch('fetchEvents'));
};

export const copyEvent = (context, data) => {
    const event = data.event;

    return jsonApi.find('calendar_item', context.state.activeMutation.id)
        .then(response => response.data)
        .then(apiEvent => {
            delete apiEvent.id;

            apiEvent.start = dateFormatter(event.start);
            if (event.end) {
                apiEvent.end = dateFormatter(event.end);
            } else {
                delete apiEvent.end;
            }

            // Fix some differences between GET and POST...
            delete apiEvent.is_editable;
            delete apiEvent.is_unfulfillable;
            delete apiEvent.is_deletable;
            delete apiEvent.has_recurring_appointments;
            apiEvent.location = { id: String(apiEvent.location_id) };
            delete apiEvent.location_id;
            if (apiEvent.calendar_item_participations) {
                apiEvent.calendar_item_participations = apiEvent.calendar_item_participations.map((item, idx) => ({
                    id: `new_${idx}`,
                    patient_id: item.patient_id,
                    indication_history_id: item.type === CalendarItemWithPerformanceCodeParticipation.name ? item.indication_history_id : undefined,
                    subscription_item_data_id: item.type === CalendarItemWithSubscriptionParticipation.name ? item.subscription_item_data_id : undefined,
                    send_confirmation: item.send_confirmation,
                    send_reminder: item.send_reminder,
                    confirmation_template_id: item.confirmation_template_id,
                    reminder_template_id: item.reminder_template_id,
                }));
            }

            return { ...apiEvent, ...data.apiEvent };
        })
        .then(apiEvent =>
            // When POST- or PATCH-ing the item the API requires the type to be suffixed with '_resource'.
            jsonApi.create(apiEvent.type + '_resource', apiEvent)
                .catch(errors => handleIgnorableErrorsResponse(errors, () => jsonApi.create(apiEvent.type + '_resource', apiEvent, { force: true })))
                .catch(errors => DialogManager.errors(errors))
        )
        .then(() => context.dispatch('fetchEvents'));
};

export const previous = (context) => {
    context.commit('setEvents', {});
    context.commit('setDate', context.getters.date.clone().subtract(1, context.getters.selectionMode));
    context.dispatch('fetchEvents');
    context.dispatch('fetchSchedules');
};

export const today = (context) => {
    context.commit('setEvents', {});
    context.commit('setDate', moment());
    context.dispatch('fetchEvents');
    context.dispatch('fetchSchedules');
};

export const next = (context) => {
    context.commit('setEvents', {});
    context.commit('setDate', context.getters.date.clone().add(1, context.getters.selectionMode));
    context.dispatch('fetchEvents');
    context.dispatch('fetchSchedules');
};

export const setMode = (context, value) => {
    context.commit('setEvents', {});
    context.commit('setMode', value);

    if (value !== CalendarMode.DAY && context.state.filter.value.length > 1) {
        if (context.state.filter.mode === CalendarFilterMode.EMPLOYEES) {
            context.commit('setFilterValue', [context.rootState.employeeId]);
        } else {
            context.commit('setFilterValue', []);
        }
    }

    context.dispatch('fetchEvents');
    context.dispatch('fetchSchedules');
};

export const setDate = (context, value) => {
    context.commit('setEvents', {});
    context.commit('setDate', value);
    context.dispatch('fetchEvents');
    context.dispatch('fetchSchedules');
};

export const setFilter = (context, filter) => {
    context.commit('setFilter', filter);
    context.dispatch('fetchEvents');
    context.dispatch('fetchSchedules');
};

export const setFilterMode = (context, mode) => {
    context.commit('setFilterMode', mode);
    context.dispatch('fetchEvents');
    context.dispatch('fetchSchedules');
};

export const setFilterValue = (context, value) => {
    context.commit('setFilterValue', value);
    context.dispatch('fetchEvents');
    context.dispatch('fetchSchedules');
};

export const toggleAnonymous = (context) => {
    context.dispatch('setAnonymous', !context.state.anonymous);
};

export const setAnonymous = (context, value) => {
    context.commit('setAnonymous', value);
    context.dispatch('fetchEvents');
};

export const openEvent = (context, value) => {
    context.commit('setOpenedEvent', value);
};

export const closeEvent = (context) => {
    if (context.state.openedEvent !== null) {
        context.commit('setOpenedEvent', null);
    }
};

export const showEventInWeekview = async(context, value) => {
    const event = (await jsonApi.find('calendar_item', value))?.data;

    if (!event || !event.employees || event.employees.length === 0) {
        return;
    }

    const employeeId = event.employees[0].id;
    const hash = context.getters.hashForEvent(event, 'calendar_item');

    context.commit('setDate', moment(event.start));
    context.commit('setMode', CalendarMode.WEEK);
    context.commit('setFilterMode', CalendarFilterMode.EMPLOYEES);
    context.commit('setFilterValue', [employeeId]);
    context.commit('setOpenedEvent', hash);

    context.dispatch('fetchEvents');
    context.dispatch('fetchSchedules');
};
