<template>
    <div :class="{'calendar__view--anonymous': anonymous, 'calendar__view--all-day-slot': showCalendarNotes}" class="calendar__view">
        <div ref="fullcalendar" />
    </div>
</template>

<script>
import $ from 'jquery';
import 'jquery-contextmenu';
import 'jquery-ui/ui/position';
import 'swiped-events';
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin, { toMoment } from '@fullcalendar/moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import scrollgridPlugin from '@fullcalendar/scrollgrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import fullCalendarLocales from '../../i18n/locales/fullcalendar';
import moment from 'moment';
import { createNamespacedHelpers } from 'vuex';
import jsonApi from '../../json-api-client';
import ModalManager from '../../services/modalManager';
import iconRenderer from '../../services/iconRenderer';
import dateFormatter from '../../i18n/dateFormatter';
import * as CalendarItemViewType from '../../enums/CalendarItemViewType';
import * as CalendarMode from '../../enums/CalendarMode';
import * as CalendarFilterMode from '../../enums/CalendarFilterMode';
import CreatesCalendarContextMenuItems from '../../mixins/Calendar/CreatesCalendarContextMenuItems';
import HandlesCalendarContextMenuItems from '../../mixins/Calendar/HandlesCalendarContextMenuItems';
import S from 'string';
import { handleIgnorableErrorsResponse } from '../../services/ignorableErrors';
import DialogManager from '../../services/DialogManager';
import AssertsEventType from '../../mixins/Calendar/AssertsEventType';

const { mapActions, mapGetters, mapState } = createNamespacedHelpers('calendar');

export default {
    mixins: [
        CreatesCalendarContextMenuItems,
        HandlesCalendarContextMenuItems,
        AssertsEventType,
    ],

    props: {
        events: {
            type: Array,
            required: true,
        },
        resources: {
            type: Array,
            required: true,
        },
        mode: {
            type: String,
            required: true,
        },
        view: {
            type: String,
            required: true,
        },
        date: {
            type: Object,
            required: true,
            validator(value) {
                return moment.isMoment(value);
            },
        },
    },

    computed: {
        ...mapGetters([
            'getEventByHash',
            'showResources',
            'showCalendarNotes',
        ]),
        ...mapState([
            'anonymous',
        ]),
        hiddenDays() {
            // if it's a "normal" view, show all the days
            if (this.$store.state.calendar.mode !== CalendarMode.DAY_PLUS_SEVEN) {
                return [];
            }

            // hide all other weekdays (other than the selected day) when showing the timeGridDayPlusSeven-view
            return [0, 1, 2, 3, 4, 5, 6].filter(day => {
                return day !== this.$store.getters['calendar/date'].day();
            });
        },
    },

    watch: {
        events() {
            this.$options.fullCalendar && this.$options.fullCalendar.refetchEvents();
        },
        date(date) {
            this.$options.fullCalendar.gotoDate(date.toDate());
        },
        view(view) {
            this.$options.fullCalendar.changeView(view);
        },
        resources() {
            this.$options.fullCalendar.refetchResources();
        },
        hiddenDays() {
            this.updateHiddenDays();
        },
        showCalendarNotes() {
            this.$options.fullCalendar.setOption('allDaySlot', this.showCalendarNotes);
        },
    },

    methods: {
        ...mapActions([
            'fetchEvents',
            'updateEvent',
            'setFilterValue',
        ]),
        openCreateNoteModal(date, extra = '') {
            ModalManager.openModal(`/calendar/note/create?date=${dateFormatter(date)}&${extra}`, undefined, undefined, 'large');
        },
        updateHiddenDays() {
            this.$options.fullCalendar.setOption('hiddenDays', this.hiddenDays);
        },
    },

    mounted() {
        const key = document.head.querySelector('meta[name="fullcalendar-scheduler-key"]');

        ModalManager.$on('modalClosed', () => {
            this.fetchEvents();
        });

        this.$el.addEventListener('swiped-left', () => {
            this.$emit('next');
        });

        this.$el.addEventListener('swiped-right', () => {
            this.$emit('previous');
        });

        const getContextMenuItems = (target, event) => {
            if (!event || this.eventIsScheduleBackground(event)) {
                if (this.view !== CalendarMode.MONTH && target.classList.contains('fc-daygrid-body')) {
                    return this.createContextMenuItemsForNoteBackground(event);
                } else {
                    return this.createContextMenuItemsForEventsBackground(event, this.$options.contextClickInfo);
                }
            } else {
                if (this.eventIsPlaceholder(event)) {
                    return this.createContextMenuItemsForPlaceholder(event, this.$options.contextClickInfo);
                } else if (this.eventIsNote(event)) {
                    return this.createContextMenuItemsForNote(event);
                } else {
                    return this.createContextMenuItemsForEvent(event);
                }
            }
        };

        const defaultContextMenuOptions = {
            build: ($trigger, e) => {
                const hash = $(e.currentTarget).data('hash');
                const event = hash ? this.getEventByHash(hash) : undefined;

                return {
                    zIndex: 10,
                    items: getContextMenuItems(e.currentTarget, event),
                };
            },
        };

        // Events and notes
        $(this.$refs.fullcalendar).contextMenu({
            selector: '.calendar-event',
            ...defaultContextMenuOptions,
        });

        // Empty slots
        $(this.$refs.fullcalendar).contextMenu({
            selector: '.fc-business',
            ...defaultContextMenuOptions,
        });

        // Events and notes
        $(this.$refs.fullcalendar).contextMenu({
            selector: '.calendar-note',
            ...defaultContextMenuOptions,
        });

        // All day slot
        $(this.$refs.fullcalendar).contextMenu({
            selector: '.fc-daygrid-body',
            ...defaultContextMenuOptions,
        });

        // Other
        $(this.$refs.fullcalendar).contextMenu({
            selector: '.fc-scroller-harness',
            ...defaultContextMenuOptions,
        });

        this.$options.fullCalendar = new Calendar(this.$refs.fullcalendar, {
            plugins: [interactionPlugin, momentPlugin, dayGridPlugin, timeGridPlugin, resourceTimeGridPlugin, scrollgridPlugin],
            schedulerLicenseKey: key ? key.content : '',
            initialDate: this.date.toDate(),
            initialView: this.view,
            locale: fullCalendarLocales[this.$i18n.locale],
            resourceOrder: 'title',
            eventDisplay: 'block',
            dayMinWidth: 125,
            slotMinTime: '06:00',
            slotMaxTime: '24:00',
            editable: true,
            headerToolbar: false,
            lazyFetching: false,
            height: '100%',
            allDaySlot: this.showCalendarNotes,
            allDayContent: this.$t('calendar.all-day'),
            defaultTimedEventDuration: '00:20:00',
            displayEventEnd: true,
            selectable: true,
            slotLabelInterval: { hours: 1 },
            slotLabelFormat: 'H:mm',
            slotDuration: '00:15:00',
            snapDuration: '00:05:00',
            nowIndicator: true,
            weekNumbers: true,
            firstDay: this.$store.state.calendar.firstday,
            hiddenDays: this.hiddenDays,
            views: {
                timeGrid: {
                    dayHeaderFormat: { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
                },
                timeGridWorkweek: {
                    type: 'timeGridWeek',
                    weekends: false,
                },
                timeGridDayPlusSeven: {
                    type: 'timeGrid',
                    duration: { days: 49 }, // 7x7, we'll hide all the other weekdays in the hiddenDays computed variable
                },
            },
            events: (fetchInfo, successCallback) => {
                successCallback(this.events);
            },
            resources: (fetchInfo, successCallback) => {
                successCallback(this.resources);
            },
            resourceLabelDidMount: ({ resource, el: element }) => {
                $(element)
                    .css({ cursor: 'pointer' })
                    .click(() => {
                        this.setFilterValue([resource.id]);
                    });
            },
            eventContent: ({ event }) => {
                let $content;

                if (!event.display || !event.display.match(/background/)) {
                    $content = $('<div class="fc-content">');

                    if (event.extendedProps.dotColor) {
                        $content.css('--dot-color', event.extendedProps.dotColor);
                    }

                    if (event.extendedProps.displayEventTime) {
                        let time = toMoment(event.start, this.$options.fullCalendar).format('HH:mm');
                        if (event.extendedProps.displayEventEnd) {
                            time += ' - ' + toMoment(event.end, this.$options.fullCalendar).format('HH:mm');
                        }

                        $content.append(`<div class="fc-event-time">${time}</div>`);
                    }

                    if (event.title) {
                        $content.append(`<div class="fc-event-title">${S(event.title).escapeHTML().toString()}</div>`);
                    }

                    if (event.extendedProps.tooltip) {
                        $content.attr('title', event.extendedProps.tooltip);
                    }

                    if (event.extendedProps.hasNote) {
                        $content.append(`<div class="calendar-event__note" title="${this.$t('calendar.note')}">${iconRenderer('info-i', 'calendar-event__note-icon')}</div>`);
                    }
                }

                return {
                    html: $content ? $content[0].outerHTML : null,
                };
            },
            eventDidMount: ({ event, el: element }) => {
                if (event.extendedProps.hash) {
                    $(element).data('hash', event.extendedProps.hash);
                }

                if (!event.display || !event.display.match(/background/)) {
                    $(element).on('dblclick', () => {
                        if (this.eventIsNote(event)) {
                            ModalManager.openModal(`/calendar/note/${event.extendedProps.apiId}/edit`, undefined, undefined, 'large');

                            return;
                        }

                        const getAppointmentCreateUrl = (event, parameterName) => {
                            const parameterValue = this.showResources
                                ? event.getResources()[0].id
                                : this.$store.state.calendar.filter.value[0];

                            return `/calendar/appointment/create?start=${dateFormatter(event.start)}&end=${dateFormatter(event.end)}&location=${event.extendedProps.locationId}&rooms=${event.extendedProps.locationRooms}&${parameterName}=${parameterValue}`;
                        };

                        if (event.extendedProps.eventType === CalendarItemViewType.EMPLOYEE_SCHEDULE_PLACEHOLDER) {
                            ModalManager.openModal(getAppointmentCreateUrl(event, 'employees'), undefined, undefined, 'large');
                        } else if (event.extendedProps.eventType === CalendarItemViewType.LOCATION_DEVICE_SCHEDULE_PLACEHOLDER) {
                            ModalManager.openModal(getAppointmentCreateUrl(event, 'devices'), undefined, undefined, 'large');
                        } else if (event.extendedProps.eventType === CalendarItemViewType.LOCATION_ROOM_SCHEDULE_PLACEHOLDER) {
                            ModalManager.openModal(getAppointmentCreateUrl(event, 'rooms'), undefined, undefined, 'large');
                        } else if (event.startEditable && event.durationEditable) {
                            ModalManager.openModal(`/calendar/appointment/${event.extendedProps.apiId}/edit`, undefined, undefined, 'large');
                        }
                    });
                }
            },
            eventAllow: (dropInfo, draggedEvent) => {
                return !this.eventIsPlaceholder(draggedEvent);
            },
            eventDrop: async({ event, oldEvent, revert }) => {
                // prevent dropping events to the all-day slot and notes to the time grid
                if ((!this.eventIsNote(event) && event.allDay) || (this.eventIsNote(event) && !event.allDay)) {
                    revert();

                    return;
                }

                if (event.extendedProps.eventType === CalendarItemViewType.NOTE) {
                    const note = (await jsonApi.find('calendar_note', event.extendedProps.apiId)).data;
                    const updateNote = { ...note, date: dateFormatter(event.start) };

                    try {
                        await jsonApi.update('calendar_note', updateNote);
                    } catch (errors) {
                        revert();

                        return DialogManager.errors(errors);
                    }

                    this.fetchEvents();

                    return;
                }

                const apiEvent = {};

                if (this.showResources) {
                    const resourceIds = event.getResources().filter(resource => resource?.id).map(resource => resource.id);
                    const currentApiEvent = (await jsonApi.find('calendar_item', event.extendedProps.apiId)).data;

                    if (resourceIds.length) {
                        switch (this.$store.state.calendar.filter.mode) {
                            case CalendarFilterMode.EMPLOYEES: {
                                apiEvent.employees = [
                                    ...currentApiEvent.employees
                                        .filter(employee => !this.resources.map(resource => resource.id).includes(employee.id))
                                        .map(employee => employee.id),
                                    ...resourceIds,
                                ].map(resourceId => ({ id: resourceId }));

                                const schedule = this.$store.getters['calendar/findScheduleByEmployeeAndDate'](
                                    resourceIds[0], dateFormatter(event.start), dateFormatter(event.end));

                                if (schedule && schedule.location_id && String(schedule.location_id) !== String(currentApiEvent.location_id)) {
                                    apiEvent.location = { id: String(schedule.location_id) };
                                    apiEvent.location_devices = schedule.device_id ? [{ id: String(schedule.device_id) }] : [];
                                    apiEvent.location_rooms = schedule.room_id ? [{ id: String(schedule.room_id) }] : [];
                                }

                                break;
                            }
                            case CalendarFilterMode.ROOMS:
                                apiEvent.location = { id: String(this.$store.getters['calendar/roomById'](resourceIds[0]).location_id) };
                                apiEvent.location_rooms = [{ id: resourceIds[0] }];
                                if (oldEvent.extendedProps.locationId !== apiEvent.location.id) {
                                    apiEvent.location_devices = [];
                                }
                                break;
                            case CalendarFilterMode.DEVICES:
                                apiEvent.location = { id: String(this.$store.getters['calendar/deviceById'](resourceIds[0]).location_id) };
                                apiEvent.location_devices = [{ id: resourceIds[0] }];
                                if (oldEvent.extendedProps.locationId !== apiEvent.location.id) {
                                    apiEvent.location_rooms = [];
                                }
                                break;
                        }
                    }
                } else if (this.$store.state.calendar.filter.mode === CalendarFilterMode.EMPLOYEES) {
                    const employeeId = this.$store.state.calendar.filter.value[0];

                    if (employeeId) {
                        const schedule = this.$store.getters['calendar/findScheduleByEmployeeAndDate'](
                            employeeId, dateFormatter(event.start), dateFormatter(event.end));

                        if (schedule && schedule.location_id && String(oldEvent.extendedProps.locationId) !== String(schedule.location_id)) {
                            apiEvent.location = { id: String(schedule.location_id) };
                            apiEvent.location_devices = schedule.device_id ? [{ id: String(schedule.device_id) }] : [];
                            apiEvent.location_rooms = schedule.room_id ? [{ id: String(schedule.room_id) }] : [];
                        }
                    }
                }

                this.updateEvent({ event, originalEvent: oldEvent, apiEvent })
                    .catch(errors => handleIgnorableErrorsResponse(
                        errors,
                        () => this.updateEvent({ event, originalEvent: oldEvent, force: true, apiEvent }),
                        () => revert()))
                    .catch(errors => {
                        revert();

                        return DialogManager.errors(errors);
                    });
            },
            eventResize: ({ event, oldEvent, revert }) => {
                this.updateEvent({ event, originalEvent: oldEvent })
                    .catch(errors => {
                        revert();

                        return DialogManager.errors(errors);
                    });
            },
            eventClick: ({ event }) => {
                if (this.eventIsAppointment(event) || this.eventIsNote(event)) {
                    this.$emit('event-click', event.extendedProps.hash);
                } else {
                    this.$emit('date-click');
                }
            },
            dateClick: ({ date, dateStr, allDay, resource, jsEvent }) => {
                if (jsEvent.button === 2) {
                    this.$options.contextClickInfo = {
                        date,
                        dateStr,
                        allDay,
                        resource,
                    };

                    return;
                }

                this.$options.doubleClickStore = this.$options.doubleClickStore || {};

                if (this.$options.doubleClickStore[dateStr] && this.$options.doubleClickStore[dateStr] > (new Date()).getTime() - 500) {
                    let extra = `${this.$store.state.calendar.filter.mode}=${this.$store.state.calendar.filter.value}`;

                    if (resource) {
                        switch (this.$store.state.calendar.filter.mode) {
                            case CalendarFilterMode.EMPLOYEES:
                                extra = `${this.$store.state.calendar.filter.mode}=${resource.id}`;
                                break;
                            case CalendarFilterMode.ROOMS:
                                extra = `location=${this.$store.getters['calendar/roomById'](resource.id).location_id}&${this.$store.state.calendar.filter.mode}=${resource.id}`;
                                break;
                            case CalendarFilterMode.DEVICES:
                                extra = `location=${this.$store.getters['calendar/deviceById'](resource.id).location_id}&${this.$store.state.calendar.filter.mode}=${resource.id}`;
                                break;
                        }
                    }

                    if (allDay) {
                        this.openCreateNoteModal(date, extra);
                        return;
                    }

                    ModalManager.openModal(`/calendar/appointment/create?start=${dateFormatter(date)}&${extra}`, undefined, undefined, 'large');
                }

                this.$options.doubleClickStore[dateStr] = (new Date()).getTime();

                this.$emit('date-click');
            },
        });

        this.$options.fullCalendar.render();
    },
};
</script>
